import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cef1994e = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _bb178ece = () => interopDefault(import('../pages/admin/bills/index.vue' /* webpackChunkName: "pages/admin/bills/index" */))
const _315ca7dc = () => interopDefault(import('../pages/admin/chargeboxes/index.vue' /* webpackChunkName: "pages/admin/chargeboxes/index" */))
const _bf1d9168 = () => interopDefault(import('../pages/admin/charges/index.vue' /* webpackChunkName: "pages/admin/charges/index" */))
const _8da41a64 = () => interopDefault(import('../pages/admin/orders/index.vue' /* webpackChunkName: "pages/admin/orders/index" */))
const _9eee2c26 = () => interopDefault(import('../pages/admin/organizations/index.vue' /* webpackChunkName: "pages/admin/organizations/index" */))
const _f35cf030 = () => interopDefault(import('../pages/admin/passes/index.vue' /* webpackChunkName: "pages/admin/passes/index" */))
const _d22f4f0a = () => interopDefault(import('../pages/admin/organizations/new.vue' /* webpackChunkName: "pages/admin/organizations/new" */))
const _30bf8273 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _717189fd = () => interopDefault(import('../pages/mileage-report.vue' /* webpackChunkName: "pages/mileage-report" */))
const _74ae9346 = () => interopDefault(import('../pages/password-reset/index.vue' /* webpackChunkName: "pages/password-reset/index" */))
const _4379dc76 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _0629f9d4 = () => interopDefault(import('../pages/password-reset/sent.vue' /* webpackChunkName: "pages/password-reset/sent" */))
const _34cfecf6 = () => interopDefault(import('../pages/password-reset/update.vue' /* webpackChunkName: "pages/password-reset/update" */))
const _6717df5c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5c6054f2 = () => interopDefault(import('../pages/organizations/_idOrga.vue' /* webpackChunkName: "pages/organizations/_idOrga" */))
const _5c789cd2 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/index" */))
const _96ef9d92 = () => interopDefault(import('../pages/organizations/_idOrga/dashboard.vue' /* webpackChunkName: "pages/organizations/_idOrga/dashboard" */))
const _e8079a92 = () => interopDefault(import('../pages/organizations/_idOrga/edit.vue' /* webpackChunkName: "pages/organizations/_idOrga/edit" */))
const _27365b02 = () => interopDefault(import('../pages/organizations/_idOrga/licenses.vue' /* webpackChunkName: "pages/organizations/_idOrga/licenses" */))
const _2c66acd4 = () => interopDefault(import('../pages/organizations/_idOrga/orders-bills/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/orders-bills/index" */))
const _2f0132d7 = () => interopDefault(import('../pages/organizations/_idOrga/parameters.vue' /* webpackChunkName: "pages/organizations/_idOrga/parameters" */))
const _010b9b24 = () => interopDefault(import('../pages/organizations/_idOrga/users/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/users/index" */))
const _448180a0 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/new.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/new" */))
const _12386588 = () => interopDefault(import('../pages/organizations/_idOrga/users/new.vue' /* webpackChunkName: "pages/organizations/_idOrga/users/new" */))
const _4cbf920d = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU" */))
const _18a5d30e = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/billing.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/billing" */))
const _2c1fa63d = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/charges.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/charges" */))
const _51f3cb00 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/charges/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/charges/index" */))
const _b5b0bdae = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/charges/connected-cable/_idCharge/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/charges/connected-cable/_idCharge/index" */))
const _0fa11a0f = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/charges/external/_idCharge/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/charges/external/_idCharge/index" */))
const _d48adffe = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/charges/internal/_idCharge/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/charges/internal/_idCharge/index" */))
const _249b17ab = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/collaborators.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/collaborators" */))
const _1d43f1ee = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/collaborators/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/collaborators/index" */))
const _53176cbc = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/collaborators/new.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/collaborators/new" */))
const _736a1c26 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/collaborators/_idCollaborator/edit.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/collaborators/_idCollaborator/edit" */))
const _2cde0892 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/dashboard.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/dashboard" */))
const _02159a66 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/delivery.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/delivery" */))
const _44adee7c = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/edit.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/edit" */))
const _d614c454 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/orders-bills.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/orders-bills" */))
const _7064284e = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/orders-bills/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/orders-bills/index" */))
const _7d337248 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/parameters.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/parameters" */))
const _6a86c01e = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/passes.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/passes" */))
const _b81d025e = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/passes/business/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/passes/business/index" */))
const _54b67e8e = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/passes/community/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/passes/community/index" */))
const _337ae628 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/passes/private/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/passes/private/index" */))
const _c5864cbc = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/passes/business/new/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/passes/business/new/index" */))
const _5fd1b2df = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/passes/community/new/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/passes/community/new/index" */))
const _1ef6850e = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/passes/private/new/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/passes/private/new/index" */))
const _28d019ff = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/passes/business/_idPass/edit.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/passes/business/_idPass/edit" */))
const _5f0748bc = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/passes/community/_idPass/edit.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/passes/community/_idPass/edit" */))
const _b889da70 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/passes/private/_idPass/edit.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/passes/private/_idPass/edit" */))
const _db472ef8 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/payment.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/payment" */))
const _5dab5b0b = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/supervised-charges/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/supervised-charges/index" */))
const _8c90c1ee = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/users/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/users/index" */))
const _617f33b9 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/vehicles.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/vehicles" */))
const _323a4b7c = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/vehicles/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/vehicles/index" */))
const _0952d1ca = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/vehicles/new.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/vehicles/new" */))
const _1edaab91 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/vehicles/_idVehicle/edit.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/vehicles/_idVehicle/edit" */))
const _3827fc76 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/zones.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/zones" */))
const _1bc13ef0 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/zones/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/zones/index" */))
const _655c8c54 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/zones/new.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/zones/new" */))
const _fe53bbb6 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/zones/transfer.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/zones/transfer" */))
const _cdea100c = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/zones/chargeboxes/order.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/zones/chargeboxes/order" */))
const _473ab5dc = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/zones/chargeboxes/_idChargeBoxes/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/zones/chargeboxes/_idChargeBoxes/index" */))
const _be7869a6 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/zones/_idZone/edit.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/zones/_idZone/edit" */))
const _337fe82a = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/delivery-points/new.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/delivery-points/new" */))
const _0bf22d18 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/supervised-charges/connected-cable/_idCharge/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/supervised-charges/connected-cable/_idCharge/index" */))
const _35a593d4 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/supervised-charges/internal/_idCharge/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/supervised-charges/internal/_idCharge/index" */))
const _262bce35 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/orders/_orderId/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/orders/_orderId/index" */))
const _341e15a2 = () => interopDefault(import('../pages/organizations/_idOrga/business-units/_idBU/delivery-points/_idDeliveryPoint/edit.vue' /* webpackChunkName: "pages/organizations/_idOrga/business-units/_idBU/delivery-points/_idDeliveryPoint/edit" */))
const _9b65b14c = () => interopDefault(import('../pages/organizations/_idOrga/orders/_orderId/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/orders/_orderId/index" */))
const _7bf84d84 = () => interopDefault(import('../pages/organizations/_idOrga/users/_idUser/index.vue' /* webpackChunkName: "pages/organizations/_idOrga/users/_idUser/index" */))
const _f3fbd570 = () => interopDefault(import('../pages/organizations/_idOrga/users/_idUser/edit.vue' /* webpackChunkName: "pages/organizations/_idOrga/users/_idUser/edit" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _cef1994e,
    name: "admin",
    children: [{
      path: "bills",
      component: _bb178ece,
      name: "admin-bills"
    }, {
      path: "chargeboxes",
      component: _315ca7dc,
      name: "admin-chargeboxes"
    }, {
      path: "charges",
      component: _bf1d9168,
      name: "admin-charges"
    }, {
      path: "orders",
      component: _8da41a64,
      name: "admin-orders"
    }, {
      path: "organizations",
      component: _9eee2c26,
      name: "admin-organizations"
    }, {
      path: "passes",
      component: _f35cf030,
      name: "admin-passes"
    }, {
      path: "organizations/new",
      component: _d22f4f0a,
      name: "admin-organizations-new"
    }]
  }, {
    path: "/login",
    component: _30bf8273,
    name: "login"
  }, {
    path: "/mileage-report",
    component: _717189fd,
    name: "mileage-report"
  }, {
    path: "/password-reset",
    component: _74ae9346,
    name: "password-reset"
  }, {
    path: "/profile",
    component: _4379dc76,
    name: "profile"
  }, {
    path: "/password-reset/sent",
    component: _0629f9d4,
    name: "password-reset-sent"
  }, {
    path: "/password-reset/update",
    component: _34cfecf6,
    name: "password-reset-update"
  }, {
    path: "/",
    component: _6717df5c,
    name: "index"
  }, {
    path: "/organizations/:idOrga?",
    component: _5c6054f2,
    name: "organizations-idOrga",
    children: [{
      path: "business-units",
      component: _5c789cd2,
      name: "organizations-idOrga-business-units"
    }, {
      path: "dashboard",
      component: _96ef9d92,
      name: "organizations-idOrga-dashboard"
    }, {
      path: "edit",
      component: _e8079a92,
      name: "organizations-idOrga-edit"
    }, {
      path: "licenses",
      component: _27365b02,
      name: "organizations-idOrga-licenses"
    }, {
      path: "orders-bills",
      component: _2c66acd4,
      name: "organizations-idOrga-orders-bills"
    }, {
      path: "parameters",
      component: _2f0132d7,
      name: "organizations-idOrga-parameters"
    }, {
      path: "users",
      component: _010b9b24,
      name: "organizations-idOrga-users"
    }, {
      path: "business-units/new",
      component: _448180a0,
      name: "organizations-idOrga-business-units-new"
    }, {
      path: "users/new",
      component: _12386588,
      name: "organizations-idOrga-users-new"
    }, {
      path: "business-units/:idBU?",
      component: _4cbf920d,
      name: "organizations-idOrga-business-units-idBU",
      children: [{
        path: "billing",
        component: _18a5d30e,
        name: "organizations-idOrga-business-units-idBU-billing"
      }, {
        path: "charges",
        component: _2c1fa63d,
        children: [{
          path: "",
          component: _51f3cb00,
          name: "organizations-idOrga-business-units-idBU-charges"
        }, {
          path: "connected-cable/:idCharge",
          component: _b5b0bdae,
          name: "organizations-idOrga-business-units-idBU-charges-connected-cable-idCharge"
        }, {
          path: "external/:idCharge",
          component: _0fa11a0f,
          name: "organizations-idOrga-business-units-idBU-charges-external-idCharge"
        }, {
          path: "internal/:idCharge",
          component: _d48adffe,
          name: "organizations-idOrga-business-units-idBU-charges-internal-idCharge"
        }]
      }, {
        path: "collaborators",
        component: _249b17ab,
        children: [{
          path: "",
          component: _1d43f1ee,
          name: "organizations-idOrga-business-units-idBU-collaborators"
        }, {
          path: "new",
          component: _53176cbc,
          name: "organizations-idOrga-business-units-idBU-collaborators-new"
        }, {
          path: ":idCollaborator/edit",
          component: _736a1c26,
          name: "organizations-idOrga-business-units-idBU-collaborators-idCollaborator-edit"
        }]
      }, {
        path: "dashboard",
        component: _2cde0892,
        name: "organizations-idOrga-business-units-idBU-dashboard"
      }, {
        path: "delivery",
        component: _02159a66,
        name: "organizations-idOrga-business-units-idBU-delivery"
      }, {
        path: "edit",
        component: _44adee7c,
        name: "organizations-idOrga-business-units-idBU-edit"
      }, {
        path: "orders-bills",
        component: _d614c454,
        children: [{
          path: "",
          component: _7064284e,
          name: "organizations-idOrga-business-units-idBU-orders-bills"
        }]
      }, {
        path: "parameters",
        component: _7d337248,
        name: "organizations-idOrga-business-units-idBU-parameters"
      }, {
        path: "passes",
        component: _6a86c01e,
        name: "organizations-idOrga-business-units-idBU-passes",
        children: [{
          path: "business",
          component: _b81d025e,
          name: "organizations-idOrga-business-units-idBU-passes-business"
        }, {
          path: "community",
          component: _54b67e8e,
          name: "organizations-idOrga-business-units-idBU-passes-community"
        }, {
          path: "private",
          component: _337ae628,
          name: "organizations-idOrga-business-units-idBU-passes-private"
        }, {
          path: "business/new",
          component: _c5864cbc,
          name: "organizations-idOrga-business-units-idBU-passes-business-new"
        }, {
          path: "community/new",
          component: _5fd1b2df,
          name: "organizations-idOrga-business-units-idBU-passes-community-new"
        }, {
          path: "private/new",
          component: _1ef6850e,
          name: "organizations-idOrga-business-units-idBU-passes-private-new"
        }, {
          path: "business/:idPass/edit",
          component: _28d019ff,
          name: "organizations-idOrga-business-units-idBU-passes-business-idPass-edit"
        }, {
          path: "community/:idPass/edit",
          component: _5f0748bc,
          name: "organizations-idOrga-business-units-idBU-passes-community-idPass-edit"
        }, {
          path: "private/:idPass/edit",
          component: _b889da70,
          name: "organizations-idOrga-business-units-idBU-passes-private-idPass-edit"
        }]
      }, {
        path: "payment",
        component: _db472ef8,
        name: "organizations-idOrga-business-units-idBU-payment"
      }, {
        path: "supervised-charges",
        component: _5dab5b0b,
        name: "organizations-idOrga-business-units-idBU-supervised-charges"
      }, {
        path: "users",
        component: _8c90c1ee,
        name: "organizations-idOrga-business-units-idBU-users"
      }, {
        path: "vehicles",
        component: _617f33b9,
        children: [{
          path: "",
          component: _323a4b7c,
          name: "organizations-idOrga-business-units-idBU-vehicles"
        }, {
          path: "new",
          component: _0952d1ca,
          name: "organizations-idOrga-business-units-idBU-vehicles-new"
        }, {
          path: ":idVehicle/edit",
          component: _1edaab91,
          name: "organizations-idOrga-business-units-idBU-vehicles-idVehicle-edit"
        }]
      }, {
        path: "zones",
        component: _3827fc76,
        children: [{
          path: "",
          component: _1bc13ef0,
          name: "organizations-idOrga-business-units-idBU-zones"
        }, {
          path: "new",
          component: _655c8c54,
          name: "organizations-idOrga-business-units-idBU-zones-new"
        }, {
          path: "transfer",
          component: _fe53bbb6,
          name: "organizations-idOrga-business-units-idBU-zones-transfer"
        }, {
          path: "chargeboxes/order",
          component: _cdea100c,
          name: "organizations-idOrga-business-units-idBU-zones-chargeboxes-order"
        }, {
          path: "chargeboxes/:idChargeBoxes",
          component: _473ab5dc,
          name: "organizations-idOrga-business-units-idBU-zones-chargeboxes-idChargeBoxes"
        }, {
          path: ":idZone/edit",
          component: _be7869a6,
          name: "organizations-idOrga-business-units-idBU-zones-idZone-edit"
        }]
      }, {
        path: "delivery-points/new",
        component: _337fe82a,
        name: "organizations-idOrga-business-units-idBU-delivery-points-new"
      }, {
        path: "supervised-charges/connected-cable/:idCharge",
        component: _0bf22d18,
        name: "organizations-idOrga-business-units-idBU-supervised-charges-connected-cable-idCharge"
      }, {
        path: "supervised-charges/internal/:idCharge",
        component: _35a593d4,
        name: "organizations-idOrga-business-units-idBU-supervised-charges-internal-idCharge"
      }, {
        path: "orders/:orderId",
        component: _262bce35,
        name: "organizations-idOrga-business-units-idBU-orders-orderId"
      }, {
        path: "delivery-points/:idDeliveryPoint?/edit",
        component: _341e15a2,
        name: "organizations-idOrga-business-units-idBU-delivery-points-idDeliveryPoint-edit"
      }]
    }, {
      path: "orders/:orderId",
      component: _9b65b14c,
      name: "organizations-idOrga-orders-orderId"
    }, {
      path: "users/:idUser",
      component: _7bf84d84,
      name: "organizations-idOrga-users-idUser"
    }, {
      path: "users/:idUser/edit",
      component: _f3fbd570,
      name: "organizations-idOrga-users-idUser-edit"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
