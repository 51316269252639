import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import useBusinessUnitStore from './businessunit'
import useSessionStore from './session'
import { User, UserJSON, UserFilter, UserBU, UserRole } from '~/interfaces/user'
import { RoleIcons, BusinessUnitRoles } from '~/interfaces/roles'

const listUserColors = [
    'red',
    'yellow',
    'orange',
    'green',
    'blue',
    'indigo',
    'purple',
    'pink'
]

const useUserStore = defineStore('user', () => {
    const updateUser = (
        context: any,
        payload: { idOrga: number | null; user: User }
    ) => {
        if (payload.user.businessUnits) {
            payload.user.businessUnits.forEach((bu) => {
                bu.roles = []
            })
        } else {
            payload.user.businessUnits = []
        }
        if (payload.user.roles) {
            payload.user.roles.forEach((r: UserRole) => {
                if (r.businessUnit && r.role) {
                    let businessUnit: UserBU | undefined =
                        payload.user.businessUnits.find(
                            (bu: {
                                id: number
                                name: string
                                roles: string[]
                            }) => bu.id === r.businessUnit
                        )
                    if (!businessUnit) {
                        businessUnit = {
                            id: r.businessUnit as number,
                            name: 'not needed',
                            roles: []
                        }
                        payload.user.businessUnits.push(businessUnit)
                    }
                    if (
                        businessUnit &&
                        businessUnit.roles &&
                        !businessUnit.roles.includes(r.role)
                    ) {
                        businessUnit.roles.push(r.role)
                    }
                }
            })
        }
        const user: UserJSON = context.$toSnake(payload.user)
        delete user.color
        delete user.roles
        delete user.business_units
        delete user.is_organization_admin

        const business_unit_roles = payload.user.businessUnits
            .filter(
                (bu: {
                    id: number
                    name: string
                    roles: BusinessUnitRoles[]
                }) => bu.roles.length > 0
            )
            .map(
                (bu: {
                    id: number
                    name: string
                    roles: BusinessUnitRoles[]
                }) => ({
                    business_unit_id: bu.id,
                    roles: bu.roles
                })
            )
        const userJSON = {
            ...user,
            business_unit_roles
        }

        let url = ''
        if (!payload.idOrga) {
            url = `/cloudapi/users/${payload.user.id}`
        } else {
            const { businessUnit } = useBusinessUnitStore()
            url = `/cloudapi/organizations/${payload.idOrga}`
            url += businessUnit?.id
                ? `/business-units/${businessUnit.id}/users`
                : '/users'
        }

        return context.$axios
            .$put(url, userJSON)
            .then((res: any) => {
                // If the idOrga is not defined, it means that the user is updating his own profile
                // So we need to update the locale in the user cookie
                if (!payload.idOrga) {
                    const userCookie = context.$auth.$storage.getCookie('user')

                    userCookie.locale = payload.user.locale
                    context.$auth.$storage.setUniversal('user', userCookie)
                }
                return Promise.resolve(res)
            })
            .catch((e: any) => Promise.reject(new Error(e)))
    }

    const deleteUser = (
        context: any,
        payload: { userId: number | null; idOrga: number }
    ) => {
        const { businessUnit } = useBusinessUnitStore()
        let url = `/cloudapi/organizations/${payload.idOrga}`
        url += businessUnit?.id
            ? `/business-units/${businessUnit.id}/users/${payload.userId}`
            : `/users/${payload.userId}`
        return context.$axios
            .$delete(url)
            .then((res: any) => Promise.resolve(res))
            .catch((e: any) => Promise.reject(new Error(e)))
    }

    const impersonate = (
        context: any,
        payload: { userId: number; password: string; login: string }
    ) => {
        const { userId, password, login } = payload
        return context.$axios
            .$post('/cloudapi/impersonate', {
                login,
                password,
                impersonated_user_id: userId
            })
            .then(async (res: any) => {
                await useSessionStore().setUpUser(context, res)
                return Promise.resolve(res)
            })
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const getUser = (
        context: any,
        payload: { userId: number; idOrga: number }
    ) => {
        const { businessUnit } = useBusinessUnitStore()
        let url = `/cloudapi/organizations/${payload.idOrga}`
        url += businessUnit?.id
            ? `/business-units/${businessUnit.id}/users/${payload.userId}`
            : `/users/${payload.userId}`
        return context.$axios
            .$get(url)
            .then((res: any) => {
                const user = context.$toCamel(res)
                user.color = getUserColor(user.id)
                let buRolesList: {
                    name: BusinessUnitRoles
                    icon: string
                }[] = []
                const setRoles = (bu: UserBU, role: BusinessUnitRoles) => {
                    const icon = RoleIcons[role]
                    return {
                        businessUnit: bu.id,
                        role,
                        icon
                    }
                }
                if (user.businessUnits) {
                    user.roles = []
                    user.businessUnits.forEach((bu: UserBU) => {
                        buRolesList = []
                        bu.roles.forEach((role: BusinessUnitRoles) => {
                            const icon: string = RoleIcons[role]
                            buRolesList.push({
                                name: role,
                                icon
                            })
                            user.roles.push({
                                businessUnit: bu.id,
                                role,
                                icon
                            })
                        })
                        bu.rolesIcons = buRolesList
                    })
                } else if (businessUnit) {
                    const bu: UserBU = {
                        id: businessUnit.id,
                        name: businessUnit.name,
                        roles: [],
                        rolesIcons: []
                    }
                    user.businessUnits = [bu]
                    user.roles = user.roles.map((role: BusinessUnitRoles) => {
                        const icon = RoleIcons[role]
                        buRolesList.push({ name: role, icon })
                        return setRoles(bu, role)
                    })
                    bu.rolesIcons = buRolesList
                }
                return Promise.resolve(user)
            })
            .catch((e: AxiosError) => Promise.reject(e))
    }

    const getUsers = (
        context: any,
        payload: { idOrga: number; idBU?: number; filters: UserFilter }
    ) => {
        const offset = ((payload.filters.pages.index - 1) *
            payload.filters.pages.perPage) as number
        const limit = payload.filters.pages.perPage as number
        const keyword = payload.filters.searchField || (null as null | string)
        const params = { offset, limit, keyword }

        let url = `/cloudapi/organizations/${payload.idOrga}`

        url += payload.idBU ? `/business-units/${payload.idBU}/users` : '/users'
        const setRoles = (bu: UserBU, role: BusinessUnitRoles) => {
            const icon = RoleIcons[role]
            return {
                businessUnit: {
                    id: bu.id,
                    name: bu.name
                },
                role,
                icon
            }
        }
        return context.$axios
            .$get(url, { params })
            .then((res: any) => {
                const result = context.$toCamel(res)
                result.users.forEach((user: User) => {
                    user.color = getUserColor(user.id)
                    if (user.businessUnits) {
                        user.roles = []
                        user.businessUnits.forEach((bu) => {
                            bu.roles.forEach((role) => {
                                user.roles!.push(setRoles(bu, role))
                            })
                        })
                    } else if (user.roles && payload.idBU) {
                        const bu = {
                            id: payload.idBU,
                            name: '',
                            roles: []
                        }
                        const roles = [] as UserRole[]
                        user.businessUnits = [bu]
                        const listRoles =
                            user.roles as unknown as BusinessUnitRoles[]
                        listRoles.forEach((userRole: BusinessUnitRoles) => {
                            roles.push(setRoles(bu, userRole))
                        })
                        user.roles = roles
                    } else {
                        user.roles = []
                    }
                })
                return Promise.resolve(result)
            })
            .catch((e: any) => Promise.reject(new Error(e)))
    }

    const getUserColor = (userId: number | null) => {
        return listUserColors[userId ? userId % listUserColors.length : 0]
    }

    return {
        updateUser,
        deleteUser,
        impersonate,
        getUser,
        getUsers,
        getUserColor
    }
})

type UserStore = Omit<
    ReturnType<typeof useUserStore>,
    keyof ReturnType<typeof defineStore>
>

export default useUserStore

export type { UserStore }
